import React from "react";
import Link from "next/link";
import { usePathname, useSearchParams, useRouter } from "next/navigation";
import { SubMenuItem } from "@/types/navigation";

interface SidebarDropdownProps {
  item: SubMenuItem[];
}

const SidebarDropdown = ({ item }: SidebarDropdownProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();

  const handleClick = (route: string, e: React.MouseEvent) => {
    e.preventDefault();
    const params = new URLSearchParams(searchParams.toString());
    const newView = new URL(route, 'http://dummy.com').searchParams.get('view');
    const basePath = route.split('?')[0];

    if (newView) {
      params.set('view', newView);
      router.push(`${basePath}?${params.toString()}`);
    } else {
      router.push(route);
    }
  };

  return (
    <>
      <ul className="my-2 flex flex-col gap-1.5 pl-9">
        {item.map((item: SubMenuItem, index: number) => {
          const currentPath = pathname.split('?')[0];
          const itemPath = item.route.split('?')[0];
          const itemView = new URL(item.route, 'http://dummy.com').searchParams.get('view');
          const isActive = currentPath === itemPath && searchParams.get('view') === itemView;

          return (
            <li key={index}>
              <Link
                href={item.route}
                onClick={(e) => handleClick(item.route, e)}
                className={`relative flex items-center gap-2.5 rounded-[7px] px-3.5 py-2 font-medium duration-300 ease-in-out ${
                  isActive
                    ? "bg-primary/[.07] text-primary dark:bg-white/10 dark:text-white"
                    : "text-dark-4 hover:bg-gray-2 hover:text-dark dark:text-gray-5 dark:hover:bg-white/10 dark:hover:text-white"
                }`}
              >
                {item.icon && (
                  <span className="flex h-4 w-4 items-center justify-center text-current">
                    {item.icon}
                  </span>
                )}
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default SidebarDropdown;
